import { Vue, Component } from 'vue-property-decorator';
import CTreeTable, { ITreeColumns } from '@/modules/budget/components/cTreeTable.vue';
import Plashki from '@/modules/plashki/Index.vue';

interface IFile {
    file: string;
    sheet: string;
    b1: string;
    b2: string;
    b3: string;
    b4: string;
    a5: string;
    income: any[];
    costs: any[];
}

@Component({
    components: {
        'c-tree-table': CTreeTable,
        'plashki': Plashki
    }
})
export default class Budget extends Vue {
    private columns: ITreeColumns[] = [
        {
            property: 'name',
            title: 'Имя',
            filterable: true,
            collapseIcon: true,
            sortable: true
        },
        {
            property: 'value',
            title: 'Значение',
            filterable: true,
            sortable: true
        }
    ];

    private page = 0;
    private tab = 0;

    private fileData: IFile[] = []; // данные
    private curPage = 0;
    private incomeData: any[] | null = null;
    private costData: any[] | null = null;

    private fileBudget: any = [];
    private budgetIncomeItems: any = null;
    private budgetCostItems: any = null;

    private async loadData() {
        await fetch('/api/integration/egov')
            .then(response => response.json())
            .then(json => (this.fileData = json));
        if (this.fileData.length < 1) { return; }
        this.selectPage();
    }

    private async loadBudget() {
        await fetch('api/integration/mangystau-excel/budget')
            .then(response => response.json())
            .then(json => (this.fileBudget = json));
        if (this.fileBudget.length < 1) { return; }
        this.selectBudgetPage();
    }

    private selectPage() {
        const incArr = this.fileData[this.curPage].income;
        const costArr = this.fileData[this.curPage].costs;
        this.incomeData = null;
        this.costData = null;
        if (incArr !== null && incArr.length > 0) { this.incomeData = this.getTreeData(incArr, true); }
        if (costArr !== null && costArr.length > 0) { this.costData = this.getTreeData(costArr, false); }
    }

    private selectBudgetPage() {
        const budgetIncomeItems = this.fileBudget[this.curPage].items;
        this.budgetIncomeItems = null;
        if (budgetIncomeItems !== null && budgetIncomeItems.length > 0) {
            if (this.curPage < 3) {
                this.budgetIncomeItems = this.getTreeMangData(budgetIncomeItems, true);
            } else {
                this.budgetIncomeItems = this.getTreeMangData(budgetIncomeItems, false);
            }
        }
    }

    private getTreeData(arr: any[], isInc: boolean): any[] {
        for (const el of arr) {
            for (const el2 of arr) {
                if (el2.moved === undefined || !el2.moved) {
                    if (this.isChild(el2, el, isInc)) {
                        if (el._children === undefined || el._children === null) {
                            el._children = [];
                        }
                        el._children.push(el2);
                        el2.moved = true;
                    }
                }
            }
        }

        const newArr: any[] = [];
        for (const el of this.cloneArr(arr)) {
            if (el.moved === undefined || !el.moved) {
                newArr.push(el);
            }
        }
        return newArr;
    }

    private isChild(child: any, par: any, isInc: boolean): boolean { // является ли дочерним элементом
        let fName = ['category', 'class', 'subclass'];
        if (!isInc) {
            fName = ['functionalGroup', 'budgetProgramAdministrator', 'program'];
        }
        if (child[fName[0]] === null || par[fName[0]] === null) { return false; }
        if (child[fName[0]] === par[fName[0]]) {
            if (par[fName[1]] === null) {
                if (child[fName[1]] !== null && child[fName[2]] === null) {
                    return true;
                }
            } else if (par[fName[1]] === child[fName[1]] && par[fName[2]] === null && child[fName[2]] !== null) {
                return true;
            }
        }
        return false;
    }

    private getTreeMangData(arr: any[], isInc: boolean): any[] {
        for (const el of arr) {
            for (const el2 of arr) {
                if (el2.moved === undefined || !el2.moved) {
                    if (this.isMangChild(el2, el, isInc)) {
                        if (el._children === undefined || el._children === null) {
                            el._children = [];
                        }
                        el._children.push(el2);
                        el2.moved = true;
                    }
                }
            }
        }

        const newArr: any[] = [];
        for (const el of this.cloneArr(arr)) {
            if (el.moved === undefined || !el.moved) {
                newArr.push(el);
            }
        }
        return newArr;
    }

    private isMangChild(child: any, par: any, isInc: boolean): boolean { // является ли дочерним элементом
        let fName = ['category', 'class', 'subclass'];
        if (!isInc) {
            fName = ['functionalGroup', 'administratorOfBudgetPrograms', 'program'];
        }
        if (child[fName[0]] === null || par[fName[0]] === null) { return false; }
        if (child[fName[0]] === par[fName[0]]) {
            if (par[fName[1]] === null) {
                if (child[fName[1]] !== null && child[fName[2]] === null) {
                    return true;
                }
            } else if (par[fName[1]] === child[fName[1]] && par[fName[2]] === null && child[fName[2]] !== null) {
                return true;
            }
        }
        return false;
    }

    private mounted() {
        this.loadData();
        this.loadBudget();
    }

    private cloneArr(arr: any[]): any[] {
        const newArr: any[] = [];
        for (const el of arr) {
            newArr.push(Object.assign({}, el));
        }
        return newArr;
    }
}