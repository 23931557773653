<template>
    <div class="highcharts-figure">
        <highcharts
            :options="chartOptions"
        ></highcharts>
    </div>
</template>

<script>
import Vue from 'vue'
import Highcharts from 'highcharts'
import Treemap from 'highcharts/modules/treemap'
import HighchartsVue from 'highcharts-vue'

Vue.use(Highcharts, Treemap, HighchartsVue)

Treemap(Highcharts)

export default {
    props: {
        pardata: {
            type: Array
        }
    },
    created() {
    },

    data() {
        return {
            chartOptions: {
                series: [{
                    type: "treemap",
                    layoutAlgorithm: 'squarified',
                    allowDrillToNode: true,
                    animationLimit: 1000,
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: '1.5em',
                            fontWeight: 400,
                            textOutline: 0
                        }
                    },
                    levelIsConstant: false,
                    levels: [{
                        level: 1,
                        dataLabels: {
                            enabled: true
                        },
                        borderWidth: 2
                    }],
                    tooltip: {
                        valueDecimals: 2
                    },
                    data: this.pardata
                }],
                title: {
                    text: 'Структура поступлений'
                }
            }
        };
    }
};
</script>

<style>
.highcharts-figure, .highcharts-data-table table {
    min-width: 320px;
    max-width: 600px;
    margin: 1em auto;
}

.highcharts-data-table table {
    border-collapse: collapse;
    border: 1px solid #EBEBEB;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

</style>