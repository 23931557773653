
























import { Vue, Component, Prop } from 'vue-property-decorator';
import { ITreeColumns } from '@/modules/budget/components/cTreeTable.vue';

export interface ICollapsHide {
    hidden: boolean;
    collapsed: boolean;
}

@Component({
    name: 'c-tree-table-item'
})
export default class CTreeTableItem extends Vue {
    @Prop({
        required: true,
        default: {}
    })
    private row!: any;
    @Prop({
        required: true,
        default: []
    })
    private columns!: ITreeColumns[];

    @Prop({
        default: { hidden: false, collapsed: false }
    })
    private collapseHide!: ICollapsHide;

    private get isHide(): boolean {
        return this.collapseHide.hidden;
    }

    private styleObj(col: ITreeColumns): any {
        if (this.getRow._chldLvl !== undefined && col.collapseIcon === true) {
            return { display: 'table-cell', 'padding-left': ((2 * this.getRow._chldLvl) + 0.5) + 'rem' };
        }
        return { display: 'table-cell' };
    }

    private get collapsed(): boolean {
        return this.collapseHide.collapsed;
    }

    private get getRow(): any {
        return Object.assign({}, this.row);
    }

    private get getColumns(): ITreeColumns[] {
        return this.columns.slice();
    }
    private clkPlus() {
        this.$emit('showChilds');
    }
    private clkMinus() {
        this.$emit('hideChilds');
    }
    private digitRate(val: any): any {
        if (typeof (val) !== 'number') { return val; }
        return val.toLocaleString('ru');
    }
}
