<template>
    <b-tabs>
        <b-modal id="edit_form" size="xl" scrollable title="Данные сервиса">
            <b-table sticky-header hover bordered striped :items="edited" :fields="modaltableFields">

            </b-table>
        </b-modal>
        <b-tab title="Сводные данные">
            <b-row>
                <b-col>
                    <b-form align="center" title="Год">
                        <b-table bordered :items="data1.data" :fields="table1Fields">
                            <template #cell(region)="row">
                                {{ row.item.region }}
                            </template>
                            <template #cell(plan)="row">
                                <div align="right">
                                    {{ numberWithCommas(row.item.plan) }}
                                </div>
                            </template>
                            <template #cell(fact)="row">
                                <div align="right">
                                    {{ numberWithCommas(row.item.fact) }}
                                </div>
                            </template>
                            <template #cell(percent)="row">
                                <div align="right">
                                    {{ row.item.percent }}
                                </div>
                            </template>
                            <template slot="custom-foot" :slot-scope="data1.sum">
                                <td>{{ data1.sum.region }}</td>
                                <td align="right">{{ numberWithCommas(data1.sum.plan) }}</td>
                                <td align="right">{{ numberWithCommas(data1.sum.fact) }}</td>
                                <td align="right">{{ data1.sum.percent }}</td>
                            </template>
                        </b-table>
                    </b-form>
                </b-col>
                <b-col cols="2" lg="4" sm="4">
                    <b-form align="center">
                        <pie-chart :options="options" :chart-data="factDataByRegion"></pie-chart>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form align="center" title="За отчетный период">
                        <b-table bordered :items="data3.data" :fields="table1Fields">
                            <template #cell(region)="row">
                                {{ row.item.region }}
                            </template>
                            <template #cell(plan)="row">
                                <div align="right">
                                    {{ numberWithCommas(row.item.plan) }}
                                </div>
                            </template>
                            <template #cell(fact)="row">
                                <div align="right">
                                    {{ numberWithCommas(row.item.fact) }}
                                </div>
                            </template>
                            <template #cell(percent)="row">
                                <div align="right">
                                    {{ row.item.percent }}
                                </div>
                            </template>
                            <template slot="custom-foot" :slot-scope="data3.sum">
                                <td>{{ data3.sum.region }}</td>
                                <td align="right">{{ numberWithCommas(data3.sum.plan) }}</td>
                                <td align="right">{{ numberWithCommas(data3.sum.fact) }}</td>
                                <td align="right">{{ data3.sum.percent }}</td>
                            </template>
                        </b-table>
                    </b-form>
                </b-col>
                <b-col cols="2" lg="4" sm="4">
                    <b-form align="center">
                        <bar-chart :options="options" :chart-data="planfactDataByRegion">
                        </bar-chart>
                    </b-form>
                </b-col>
            </b-row>
        </b-tab>
        <b-tab title="Данные по районам и поступлениям">
            <b-tabs>
                <b-tab v-for="(dat) in planfactDataByRegionCode" v-bind:key="dat[0]+'-card-key'"
                       :title="dat[0]">
                    <chart :pardata="dat[1].graph"></chart>
                    <b-form align="center">
                        <b-table sticky-header hover bordered striped :items="dat[1].table" :fields="tabb2Fld">
                            <template #cell(code)="row">
                                <div  v-bind:class="{katclas:row.item.iskat}">
                                {{ row.item.code }}
                                </div>
                            </template>
                            <template #cell(name)="row">
                                <div align="left" class="code_text" v-bind:class="{katclas:row.item.iskat}">
                                    {{ row.item.name }}
                                </div>
                            </template>
                            <template #cell(plan)="row">
                                <div align="right" v-bind:class="{katclas:row.item.iskat}">
                                    {{ numberWithCommasMln(row.item.plan) }}
                                </div>
                            </template>
                            <template #cell(last_fact)="row">
                                <div align="right" v-bind:class="{katclas:row.item.iskat}">
                                    {{ numberWithCommasMln(row.item.last_fact) }}
                                </div>
                            </template>
                            <template #cell(percent)="row">
                                <div align="right" v-bind:class="{katclas:row.item.iskat}">
                                    {{ (row.item.last_fact / row.item.plan * 100).toFixed(2) }}
                                </div>
                            </template>
                        </b-table>
                    </b-form>
                </b-tab>
            </b-tabs>
        </b-tab>
        <b-tab title="Исполнение плана по районам">
            <b-form v-for="(dat) in graph1" v-bind:key="dat[0]+'-card-key'"
                    :title="dat[0]" align="center"
            >
                <b-row>
                    <b-col cols="6">
                        <pie-chart :options="options" :chart-data="dat[1]" class="my-chart"></pie-chart>
                    </b-col>
                    <b-col cols="6">
                        <bar-chart :options="options" :chart-data="graph2.get(dat[0])" class="my-chart"></bar-chart>
                    </b-col>
                </b-row>
            </b-form>
        </b-tab>
        <b-tab title="Крупные плательщики (топ 10)">
            <b-form align="center">
                <b-table bordered :items="data3OnlyOrgs" :fields="table2Fields">
                    <template #cell(code)="row">
                        {{ row.item.tax }}
                    </template>
                    <template #cell(name)="row">
                        <div align="left" class="code_text">
                            {{ row.item.name }}
                        </div>
                    </template>
                    <template #cell(plan)="row">
                        <div align="right">
                            {{ numberWithCommas(row.item.plan) }}
                        </div>
                    </template>
                    <template #cell(fact)="row">
                        <div align="right">
                            {{ numberWithCommas(row.item.fact) }}
                        </div>
                    </template>
                    <template #cell(percent)="row">
                        <div align="right">
                            {{ numberWithCommas(row.item.percent) }}
                        </div>
                    </template>
                    <template #cell(top)="row">
<!--                        <b-button v-if="row.item.orgs" size="sm"-->
<!--                                  @click="openOrgs(row.item, row.index, $event.target)" class="mr-1 my_button">-->
<!--                            Топ-->
<!--                        </b-button>-->
                        <b-button size="sm" @click="openOrgsDetail(row)" class="mr-2">
                            {{ row.detailsShowing ? 'Скрыть' : 'Показать' }} Организации
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card :title="row.item.tax + ' - ' + getTaxName(row.item.tax)">
                            <b-table sticky-header hover bordered striped :items="edited" :fields="modaltableFields" table-variant="info" tbody-transition-props="1s">
                                <template #cell(n)="_row">
                                    <div align="left">
                                        {{ _row.index+1 }}
                                    </div>
                                </template>
                                <template #cell(org)="_row">
                                    <div align="left">
                                        {{ _row.item.org }}
                                    </div>
                                </template>
                                <template #cell(sum)="_row">
                                    <div align="right">
                                        {{ numberWithCommasMln(_row.item.sum) }}
                                    </div>
                                </template>
                                <template #cell(sum_year)="_row">
                                    <div align="right">
                                        {{ numberWithCommasMln(_row.item.sum_year) }}
                                    </div>
                                </template>
                                <template #cell(percent)="_row">
                                    <div align="right">
                                        {{ (_row.item.sum_year/1000/row.item.fact*100).toFixed(2) }}
                                    </div>
                                </template>
                            </b-table>
                            <b-button size="sm" @click="row.toggleDetails">Скрыть Детали</b-button>
                        </b-card>
                    </template>
                    <template slot="custom-foot" :slot-scope="data2.sum">
                        <td align="center">{{ data2.sum.tax }}</td>
                        <td align="right">{{ data2.sum.plan }}</td>
                        <td align="right">{{ data2.sum.fact }}</td>
                        <td align="right">{{ data2.sum.percent }}</td>
                    </template>
                </b-table>
            </b-form>
        </b-tab>
        <b-tab v-if="false" title="Сводный план old">
            <b-row>
                <b-col>
                    <h3>Год</h3>
                    <b-card>
                        <chart :pardata="dat"></chart>
                    </b-card>
                </b-col>
                <b-col>
                    <b-form align="center" title="data2.data">
                        <b-table sticky-header hover bordered striped :items="data2.data" :fields="table2Fields">
                            <template #cell(top)="row">
                                <b-button v-if="row.item.orgs" size="sm"
                                          @click="openOrgs(row.item, row.index, $event.target)" class="mr-1 my_button">
                                    Топ
                                </b-button>
                            </template>
                            <template slot="custom-foot" :slot-scope="data2.sum">
                                <td align="left">{{ data2.sum.tax }}</td>
                                <td align="right">{{ data2.sum.plan }}</td>
                                <td align="right">{{ data2.sum.fact }}</td>
                                <td align="right">{{ data2.sum.percent }}</td>
                            </template>
                        </b-table>
                    </b-form>
                </b-col>
                <b-col>
                    <h3>Месяц</h3>
                    <b-col>
                        <b-form align="center" title="data4.data">
                            <b-table sticky-header hover bordered striped :items="data4.data" :fields="table2Fields">
                                <template #cell(top)="row">
                                    <b-button v-if="row.item.orgs" size="sm"
                                              @click="openOrgs(row.item, row.index, $event.target)"
                                              class="mr-1 my_button">
                                        Топ
                                    </b-button>
                                </template>
                                <template slot="custom-foot" :slot-scope="data4.sum">
                                    <td>{{ data4.sum.tax }}</td>
                                    <td>{{ data4.sum.plan }}</td>
                                    <td>{{ data4.sum.fact }}</td>
                                    <td>{{ data4.sum.percent }}</td>
                                </template>
                            </b-table>
                        </b-form>
                    </b-col>
                </b-col>
                <b-col>
                    <b-form title="data5.data">
                        <b-table-simple sticky-header hover bordered striped :fields="table1Fields">
                            <b-thead>
                                <b-tr align="center">
                                    <b-th>Структура</b-th>
                                    <b-th>Налоги</b-th>
                                    <b-th>План, тыс.тг</b-th>
                                    <b-th>Факт, тыс.тг</b-th>
                                    <b-th>Исполнение, %</b-th>
                                    <b-th>Топы</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(item, ind) in data5.data" :key="'tr_dat5_'+ind">
                                    <b-td v-if="item.gr" align="center" :rowspan="item.len">
                                        {{ item.gr }}
                                    </b-td>
                                    <b-td align="center">
                                        {{ item.tax }}
                                    </b-td>
                                    <b-td align="right">
                                        {{ item.plan }}
                                    </b-td>
                                    <b-td align="right">
                                        {{ item.fact }}
                                    </b-td>
                                    <b-td align="right">
                                        {{ item.percent }}
                                    </b-td>
                                    <b-td align="center">
                                        <b-button v-if="item.orgs" size="sm" @click="openOrgs(item)"
                                                  class="mr-1 my_button">
                                            Топ
                                        </b-button>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tfoot>
                                <b-tr>
                                    <b-td>

                                    </b-td>
                                    <b-td align="center">
                                        {{ data5.sum.tax }}
                                    </b-td>
                                    <b-td align="right">
                                        {{ data5.sum.plan }}
                                    </b-td>
                                    <b-td align="right">
                                        {{ data5.sum.fact }}
                                    </b-td>
                                    <b-td align="right">
                                        {{ data5.sum.percent }}
                                    </b-td>
                                </b-tr>
                            </b-tfoot>
                        </b-table-simple>
                    </b-form>
                </b-col>
            </b-row>
        </b-tab>
    </b-tabs>
</template>

<script>
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import data from '@/../public/budget.json';
import BarChart from './charts/BarChart';
import PieChart from './charts/PieChart';
import Chart from './charts/Chart';
import org_data from '@/../public/org_data_bin_top.json';
import HighchartsVue from 'highcharts-vue';
import Vue from 'vue';
import Temp from '@/modules/ui-playground/Temp';

Vue.use(HighchartsVue);
const divider = 1000
export default {
    name: 'Budjet-new',
    components: {
        BarChart,
        PieChart,
        Chart
    },

    data () {
        return {
            edited: [],
            tabFld: [
                {
                    key: 'name',
                    label: 'Клд',
                    sortable: true
                },
                {
                    key: 'parent',
                    label: 'Район',
                    sortable: true
                },
                {
                    key: 'value',
                    label: 'Сумма',
                    sortable: true
                }
            ],
            tabb2Fld: [
                {
                    key: 'code',
                    label: 'Код',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Наименование поступления',
                    sortable: true,
                    tdClass: 'nameOfTheClass'
                },
                {
                    key: 'plan',
                    label: 'План, тыс.тг',
                    sortable: true
                },
                {
                    key: 'last_fact',
                    label: 'Факт, тыс. тг',
                    sortable: true
                },
                {
                    key: 'percent',
                    label: 'Исполнение, %',
                    sortable: true
                }
            ],
            table1Fields: [
                {
                    key: 'region',
                    label: 'Район',
                    sortable: true
                },
                {
                    key: 'plan',
                    label: 'План, тыс.тг',
                    sortable: true
                },
                {
                    key: 'fact',
                    label: 'Факт, тыс. тг',
                    sortable: true
                },
                {
                    key: 'percent',
                    label: 'Исполнение, %',
                    sortable: true
                }
            ],
            table2Fields: [
                {
                    key: 'tax',
                    label: 'Налоги',
                    sortable: true
                },
                {
                    key: 'plan',
                    label: 'План, тыс.тг',
                    sortable: true
                },
                {
                    key: 'fact',
                    label: 'Факт, тыс. тг',
                    sortable: true
                },
                {
                    key: 'percent',
                    label: 'Исполнение, %',
                    sortable: true
                },
                {
                    key: 'top',
                    label: 'Действия',
                    sortable: true
                }
            ],
            modaltableFields: [
                {
                    key: 'n',
                    label: '№'
                },
                {
                    key: 'org',
                    label: 'Организация',
                    sortable: true
                },
                {
                    key: 'sum',
                    label: 'Сумма',
                    sortable: true
                },
                {
                    key: 'sum_year',
                    label: 'Сумма с начала года',
                    sortable: true
                },
                {
                    key: 'percent',
                    label: 'Доля в общем поступлении',
                    sortable: true
                }
            ],
            data: [],
            options: {
                responsive: true,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            },
            datacollection: null
        };
    },
    methods: {
        loadData () {
            this.data = data;
        },
        numberWithCommas (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00a0');
        },
        numberWithCommasMln (x) {
            return (parseFloat(x) / 1000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00a0');
        },
        fillData () {
            this.datacollection = {
                labels: ['jkdfdfhjkfdhkjfh', 'dhkldsjlkgjl  dfgkjd sgdfdsg  gds fg sdfg sd gsdfg ', 4, 55],
                datasets: [
                    {
                        label: 'Data 1',
                        backgroundColor: 'red',
                        data: [13, 12, 13]
                    },
                    {
                        label: 'Data 2',
                        backgroundColor: 'gray',
                        data: [30, 40, 6]
                    },
                    {
                        label: 'Data 3',
                        backgroundColor: 'white',
                        data: [30, 40, 15]
                    }
                ]
            };
        },
        getRandomInt () {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
        },
        getOrgData (code, month) {
            const rs = [];
            for (let j = month; j >= 1; j--) {
                org_data.forEach(function (item) {
                    if ((item.code == code) & (item.month == j)) {
                        rs.push(item);
                    }
                });
                if (rs.length > 0) {
                    return rs;
                }
            }
            if (rs.length == 0) {
                return undefined;
            }
            return rs;
        },
        openOrgs (item, index, target) {
            this.edited = item.orgs;
            this.$bvModal.show('edit_form');
        },
        openOrgsDetail (row) {
            row.toggleDetails()
            this.edited = row.item.orgs;
        },
        getTaxName(code) {
            console.log('getTaxName', code)
            let res = ''
            let cnt =0
            data.some(function (item) {
                if (item.code===code) {
                    if (item.name.length>res.length) {
                        res = item.name
                    }
                    cnt++
                    if (cnt>100) {
                        return res
                    }
                }
            })
            return res
        }
    },
    computed: {
        dat () {
            const regions = new Map();

            function mergeArray (array) {
                const o = array.reduce(function (init, each) {
                    if ((each.month === 10) && (each.region !== 430101)) {
                        regions.set(each.region, each.region_name);
                        const d = {
                            parent: (each.region).toString(),
                            name: each.code.toString() + ' ' + each.name,
                            value: parseFloat((each.all_sum / divider).toFixed(2))
                        };
                        init.push(d);
                        if (d.value > 100) {

                        }
                    }
                    return init;
                }, []);
                const backgroundColor = [
                    '#EF5350', '#EC407A', '#AB47BC',
                    '#42A5F5', '#9CCC65', '#FF7043',
                    '#8D6E63', '#FFEE58', '#26A69A',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ];
                let i = 0;
                regions.forEach(function (value1, value2, set) {
                    const d = {
                        id: value2.toString(),
                        name: value1.toString(),
                        color: backgroundColor[i]
                    };
                    i++;
                    o.splice(0, 0, d);
                });
                return o;
            }

            const res = mergeArray(data);
            // console.log(res)
            return res;

            return [
                {
                    id: 'A',
                    name: 'Apples',
                    color: '#EC2500'
                }, {
                    id: 'B',
                    name: 'Bananas',
                    color: '#ECE100'
                }, {
                    id: 'O',
                    name: 'Oranges',
                    color: '#EC9800'
                }, {
                    name: 'Anne',
                    parent: 'A',
                    value: 5
                }, {
                    name: 'Rick',
                    parent: 'A',
                    value: 3
                }, {
                    name: 'Peter',
                    parent: 'A',
                    value: 4
                }, {
                    name: 'Anne',
                    parent: 'B',
                    value: 4
                }, {
                    name: 'Rick',
                    parent: 'B',
                    value: 10
                }, {
                    name: 'Peter',
                    parent: 'B',
                    value: 1
                }, {
                    name: 'Anne',
                    parent: 'O',
                    value: 23
                }, {
                    name: 'Rick',
                    parent: 'O',
                    value: 3
                }, {
                    id: 'Kiwi',
                    name: 'Peter',
                    parent: 'O',
                    value: 3
                }, {
                    name: 'Susanne',
                    parent: 'Kiwi',
                    value: 2,
                    color: '#9EDE00'
                },
                {
                    name: 'Se4rik',
                    parent: 'Kiwi',
                    value: 6,
                    color: '#9EDE00'
                }
            ];
        },
        data1 () {
            let all_fact = 0;
            let all_plan = 0;

            function mergeArray (array) {
                function getLastFact (arr, month, region_name) {
                    for (let j = month; j >= 1; j--) {
                        const r = arr.reduce(function (i, e) {
                            if ((e.region_name === region_name) & (e.month === j)) {
                                i = i + e.all_sum;
                            }
                            return i;
                        }, 0);
                        if (r > 0) {
                            return r;
                        }
                    }
                    return 0;
                }

                const o = array.reduce(function (init, each) {
                    if (each.month === 12) {
                        const ex = init.get(each.region_name);
                        if (ex) {
                            ex.plan += each.plan;
                            all_plan += each.plan;
                        } else {
                            const calc = getLastFact(array, 12, each.region_name);
                            init.set(each.region_name, {fact: calc, plan: each.plan});
                            all_fact += calc;
                            all_plan += each.plan;
                        }
                    }
                    return init;
                }, new Map());
                const r = [];
                o.forEach(function (value1, value2, set) {
                    const d = {
                        region: value2,
                        plan: (value1.plan / divider).toFixed(2),
                        fact: (value1.fact / divider).toFixed(2),
                        percent: (value1.fact / value1.plan * 100).toFixed(2)
                    };
                    r.push(d);
                });
                const sum = {
                    region: 'Итого',
                    plan: (all_plan / divider).toFixed(2),
                    fact: (all_fact / divider).toFixed(2),
                    percent: (all_fact / all_plan * 100).toFixed(2)
                };
                const result = {
                    data: r,
                    sum: sum
                };
                return result;
            }

            const res = mergeArray(data);
            return res;
        },
        data2 () {
            let all_fact = 0;
            let all_plan = 0;
            const that = this;

            function mergeArray (array) {
                function getLastFact (arr, month, code) {
                    for (let j = month; j >= 1; j--) {
                        const r = arr.reduce(function (i, e) {
                            if ((e.code === code) & (e.month === j)) {
                                i = i + e.all_sum;
                            }
                            return i;
                        }, 0);
                        if (r > 0) {
                            return r;
                        }
                    }
                    return 0;
                }

                const o = array.reduce(function (init, each) {
                    if (each.month === 12) {
                        const ex = init.get(each.code);
                        if (ex) {
                            ex.plan += each.plan;
                            all_plan += each.plan;
                        } else {
                            const calc = getLastFact(array, 12, each.code);
                            init.set(each.code, {fact: calc, plan: each.plan});
                            all_fact += calc;
                            all_plan += each.plan;
                        }
                    }
                    return init;
                }, new Map());
                const r = [];
                o.forEach(function (value1, value2, set) {
                    const d = {
                        tax: value2,
                        plan: (value1.plan / divider).toFixed(2),
                        fact: (value1.fact / divider).toFixed(2),
                        percent: (value1.fact / value1.plan * 100).toFixed(2),
                        orgs: that.getOrgData(value2, 12)
                    };
                    r.push(d);
                });
                const sum = {
                    tax: 'Итого',
                    plan: (all_plan / divider).toFixed(2),
                    fact: (all_fact / divider).toFixed(2),
                    percent: (all_fact / all_plan * 100).toFixed(2)
                };
                const result = {
                    data: r,
                    sum: sum
                };
                return result;
            }

            const res = mergeArray(data);
            return res;
        },
        data3 () {
            let all_fact = 0;
            let all_plan = 0;

            function mergeArray (array) {
                const o = array.reduce(function (init, each) {
                    if (each.month === 10) {
                        const ex = init.get(each.region_name);
                        if (ex) {
                            ex.fact += each.all_sum;
                            ex.plan += each.plan;
                            all_fact += each.all_sum;
                            all_plan += each.plan;
                        } else {
                            init.set(each.region_name, {fact: each.all_sum, plan: each.plan});
                            all_fact += each.all_sum;
                            all_plan += each.plan;
                        }
                    }
                    return init;
                }, new Map());
                const r = [];
                o.forEach(function (value1, value2, set) {
                    const d = {
                        region: value2,
                        plan: (value1.plan / divider).toFixed(2),
                        fact: (value1.fact / divider).toFixed(2),
                        percent: (value1.fact / value1.plan * 100).toFixed(2)
                    };
                    r.push(d);
                });
                const sum = {
                    region: 'Итого',
                    plan: (all_plan / divider).toFixed(2),
                    fact: (all_fact / divider).toFixed(2),
                    percent: (all_fact / all_plan * 100).toFixed(2)
                };
                const result = {
                    data: r,
                    sum: sum
                };
                return result;
            }

            const res = mergeArray(data);
            return res;
        },
        data4 () {
            let all_fact = 0;
            let all_plan = 0;
            const that = this;

            function mergeArray (array) {
                const o = array.reduce(function (init, each) {
                    if (each.month === 10) {
                        const ex = init.get(each.code);
                        if (ex) {
                            ex.fact = ex.fact + each.all_sum;
                            ex.plan = ex.plan + each.plan;
                            all_fact = all_fact + each.all_sum;
                            all_plan = all_plan + each.plan;
                        } else {
                            init.set(each.code, {fact: each.all_sum, plan: each.plan});
                            all_fact = all_fact + each.all_sum;
                            all_plan = all_plan + each.plan;
                        }
                    }
                    return init;
                }, new Map());
                const r = [];
                o.forEach(function (value1, value2, set) {
                    const d = {
                        tax: value2,
                        plan: (value1.plan / divider).toFixed(2),
                        fact: (value1.fact / divider).toFixed(2),
                        percent: (value1.fact / value1.plan * 100).toFixed(2),
                        orgs: that.getOrgData(value2, 10)
                    };
                    r.push(d);
                });
                const sum = {
                    tax: 'Итого',
                    plan: (all_plan / divider).toFixed(2),
                    fact: (all_fact / divider).toFixed(2),
                    percent: (all_fact / all_plan * 100).toFixed(2)
                };
                const result = {
                    data: r,
                    sum: sum
                };
                return result;
            }

            const res = mergeArray(data);
            return res;
        },
        data5 () {
            let all_fact = 0;
            let all_plan = 0;
            const that = this;

            function mergeArray (array) {
                const o = array.reduce(function (init, each) {
                    if (each.month === 10) {
                        const ex = init.get(each.code);
                        if (ex) {
                            ex.fact += each.all_sum;
                            ex.plan += each.plan;
                            all_fact += each.all_sum;
                            all_plan += each.plan;
                        } else {
                            init.set(each.code, {fact: each.all_sum, plan: each.plan});
                            all_fact += each.all_sum;
                            all_plan += each.plan;
                        }
                    }
                    return init;
                }, new Map());
                const r = [];
                o.forEach(function (value1, value2, set) {
                    const d = {
                        tax: value2,
                        plan: (value1.plan / divider).toFixed(2),
                        fact: (value1.fact / divider).toFixed(2),
                        percent: (value1.fact / value1.plan * 100).toFixed(2),
                        orgs: that.getOrgData(value2, 10)
                    };
                    r.push(d);
                });
                const sum = {
                    tax: 'Итого',
                    plan: (all_plan / divider).toFixed(2),
                    fact: (all_fact / divider).toFixed(2),
                    percent: (all_fact / all_plan * 100).toFixed(2)
                };
                const result = {
                    data: r,
                    sum: sum
                };
                return result;
            }

            function groupArray (array) {
                const o = array.reduce(function (init, each) {
                    const par_code = each.tax.toString().substring(0, 1);
                    // console.log(par_code)
                    if (!init.get(par_code)) {
                        const ch = [
                            {tax: each.tax, plan: each.plan, fact: each.fact, orgs: each.orgs}
                        ];
                        init.set(par_code, ch);
                    } else {
                        init.get(par_code).push({tax: each.tax, plan: each.plan, fact: each.fact, orgs: each.orgs});
                    }
                    return init;
                }, new Map());
                const r = [];
                // console.log('o', o)
                o.forEach(function (value1, value2, set) {
                    let cnt = value1.length;
                    value1.forEach(function (item, arr) {
                        const d = {
                            gr: cnt === value1.length ? value2 : undefined,
                            len: value1.length,
                            tax: item.tax,
                            plan: (item.plan),
                            fact: (item.fact),
                            percent: (item.fact / item.plan * 100).toFixed(2),
                            orgs: item.orgs
                        };
                        cnt -= 1;
                        r.push(d);
                    });
                });
                return r;
            }

            const res = mergeArray(data);
            const dat = groupArray(res.data);
            const r = {
                data: dat,
                sum: res.sum
            };
            // console.log(r)
            return r;
        },
        graph1 () {
            function mergeArray (array) {
                const o = array.reduce(function (init, each) {
                    if (each.month === 10) {
                        const reg = init.get(each.region_name);
                        if (reg) {
                            const ex = reg.get(each.code);
                            if (ex) {
                                ex.fact += each.all_sum;
                                ex.plan += each.plan;
                            } else {
                                reg.set(each.code, {fact: each.all_sum, plan: each.plan});
                            }
                        } else {
                            init.set(each.region_name, new Map());
                        }
                    }
                    return init;
                }, new Map());
                // console.log('oooooooo', o)
                const all = new Map();
                o.forEach(function (value1, value2, set) {
                    const arr = [];
                    value1.forEach(function (val1, val2, set) {
                        // console.log(val1, val2)
                        const row = {
                            code: val2,
                            counter: parseFloat((val1.fact / divider).toFixed(2))
                        };
                        arr.push(row);
                    });
                    arr.sort((a, b) => (a.counter < b.counter) ? 1 : -1);
                    all.set(value2, arr);
                });
                // console.log(all)
                all.forEach(function (value1, value2, set) {
                    const r = {
                        labels: [],
                        datasets: [
                            {
                                data: [],
                                backgroundColor: [
                                    '#EF5350', '#EC407A', '#AB47BC',
                                    '#42A5F5', '#9CCC65', '#FF7043',
                                    '#8D6E63', '#FFEE58', '#26A69A',
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)'
                                ]
                            }
                        ]
                    };
                    const other = {
                        code: 'Прочие',
                        counter: 0
                    };
                    value1.forEach(function (item, index, set) {
                        if (index < 5) {
                            r.labels.push(item.code);
                            r.datasets[0].data.push(item.counter);
                        } else {
                            other.counter += item.counter;
                        }
                    });
                    r.labels.push(other.code);
                    r.datasets[0].data.push(other.counter);
                    // console.log(value2, r)
                    all.set(value2, r);
                });
                // console.log(all)
                return all;
            }

            const res = mergeArray(data);
            return res;
        },
        graph2 () {
            function mergeArray (array) {
                const o = array.reduce(function (init, each) {
                    const reg = init.get(each.region_name);
                    if (reg) {
                        const ex = reg.get(each.month);
                        if (ex) {
                            ex.fact += each.all_sum;
                            ex.plan += each.plan;
                        } else {
                            reg.set(each.month, {fact: each.all_sum, plan: each.plan});
                        }
                    } else {
                        init.set(each.region_name, new Map());
                    }
                    return init;
                }, new Map());
                // console.log('oooooooo', o)
                const all = new Map();
                o.forEach(function (value1, value2, set) {
                    const arr = [];
                    value1.forEach(function (val1, val2, set) {
                        // console.log(val1, val2)
                        const row = {
                            code: val2,
                            plan: parseFloat((val1.plan / divider).toFixed(2)),
                            fact: parseFloat((val1.fact / divider).toFixed(2))
                        };
                        arr.push(row);
                    });
                    arr.sort((a, b) => (a.code > b.code) ? 1 : -1);
                    all.set(value2, arr);
                });
                // console.log(all)
                all.forEach(function (value1, value2, set) {
                    const r = {
                        labels: [],
                        datasets: [
                            {
                                data: [],
                                backgroundColor: [
                                    '#EF5350', '#EC407A', '#AB47BC',
                                    '#42A5F5', '#9CCC65', '#FF7043',
                                    '#8D6E63', '#FFEE58', '#26A69A',
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)'
                                ]
                            },
                            {
                                data: [],
                                backgroundColor: [
                                    '#42A5F5', '#9CCC65', '#FF7043',
                                    '#8D6E63', '#FFEE58', '#26A69A',
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)'
                                ]
                            }
                        ]
                    };
                    value1.forEach(function (item, index, set) {
                        if (index < 15) {
                            r.labels.push(item.code);
                            r.datasets[0].label = 'План, тыс.тг';
                            r.datasets[0].backgroundColor = '#9CCC65';
                            r.datasets[0].data.push(item.plan);
                            r.datasets[1].label = 'Факт, тыс.тг';
                            r.datasets[1].backgroundColor = '#26A69A';
                            r.datasets[1].data.push(item.fact);
                        }
                    });
                    all.set(value2, r);
                });
                return all;
            }

            const res = mergeArray(data);
            return res;
        },
        factDataByRegion () {
            function mergeArray (array) {
                const o = array.reduce(function (init, each) {
                    if (each.month == 10) {
                        const reg = init.get(each.region);
                        if (reg) {
                            reg.sum = reg.sum + each.all_sum;
                        } else {
                            const s = {
                                sum: each.all_sum,
                                region_name: each.region_name
                            };
                            init.set(each.region, s);
                        }
                    }
                    return init;
                }, new Map());
                const labels = [];
                const dt = [];
                o.forEach(function (value1, value2, set) {
                    if (value1) {
                        labels.push(value1.region_name);
                        dt.push((value1.sum / divider).toFixed(2));
                    }
                });
                const dr = {
                    labels: labels,
                    datasets: [
                        {
                            data: dt,
                            backgroundColor: [
                                '#EF5350', '#EC407A', '#AB47BC',
                                '#42A5F5', '#9CCC65', '#FF7043',
                                '#8D6E63', '#FFEE58', '#26A69A',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ]
                        }
                    ]
                };
                return dr;
            }
            const r = mergeArray(data);
            console.log('factDataByRegion', r)
            return r;
        },
        planfactDataByRegion () {
            function mergeArray (array) {
                const o = array.reduce(function (init, each) {
                    if (each.month == 10) {
                        const reg = init.get(each.region);
                        if (reg) {
                            reg.sum = reg.sum + each.all_sum;
                            reg.plan = reg.plan + each.plan;
                        } else {
                            const s = {
                                sum: each.all_sum,
                                plan: each.plan,
                                region_name: each.region_name
                            };
                            init.set(each.region, s);
                        }
                    }
                    return init;
                }, new Map());
                const labels = [];
                const dt = [];
                const dp = [];
                o.forEach(function (value1, value2, set) {
                    if (value1) {
                        labels.push(value1.region_name);
                        dt.push((value1.sum / divider).toFixed(2));
                        dp.push((value1.plan / divider).toFixed(2));
                    }
                });
                const dr = {
                    labels: labels,
                    datasets: [
                        {
                            label: 'План, тыс.тг',
                            data: dp,
                            backgroundColor: '#EF5350'
                        },
                        {
                            label: 'Факт, тыс.тг',
                            data: dt,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)'
                        }
                    ]
                };
                return dr;
            }

            const r = mergeArray(data);
            // console.log('rdata', r)
            return r;
        },
        planfactDataByRegionCode () {
            const that = this;

            function mergeArray (array) {
                function getLastFact (arr, month, code, region) {
                    for (let j = month; j >= 1; j--) {
                        const r = arr.reduce(function (i, e) {
                            if ((e.region === region) & (e.code === code) & (e.month === j)) {
                                i = i + e.all_sum;
                            }
                            return i;
                        }, 0);
                        if (r > 0) {
                            return r;
                        }
                    }
                    return 0;
                }

                const regionsMap = new Map();
                const o = array.reduce(function (init, each) {
                        if (each.month === 12) {
                            let regData = regionsMap.get(each.region_name);
                            if (!regData) {
                                regData = new Map();
                                regionsMap.set(each.region_name, regData);
                            }
                            let codeData = regData.get(each.code);
                            if (!codeData) {
                                codeData = [];
                                regData.set(each.code, codeData);
                            }
                            const dat = Object.assign(each)
                            dat.last_fact = getLastFact(array, each.month, each.code, each.region)
                            codeData.push(dat);
                        }
                    }
                );
                const grinit =
                    regionsMap.forEach(function (value, key, map) {
                        const arr = []
                        const gr = [
                            {id: '1', name: '1. Налоговые поступления', color: '#EF5350'},
                            {id: '2', name: '2. Неналоговые поступления', color: '#EC407A'},
                            {id: '3', name: '3. Поступления от продажи основного капитала', color: '#AB47BC'},
                            {id: '4', name: '4. Поступления трансфертов', color: '#42A5F5'},
                            {id: '5', name: '5. Погашение бюджетных кредитов', color: '#9CCC65'},
                            {
                                id: '6',
                                name: '6. Поступления от продажи финансовых активов государства',
                                color: '#FF7043'
                            },
                            {id: '7', name: '7. Поступления займов', color: '#8D6E63'},
                            {id: '8', name: '8. Используемые остатки бюджетных средств', color: '#FFEE58'}
                        ]
                        function getKatName(kat) {
                            let res = ""
                            gr.some(function (item) {
                                if (item.id==kat) {
                                    res = item.name.substring(2)
                                    return res
                                }
                            })
                            return res
                        }
                        let kat = '0'
                        let sumkat= {}
                        value.forEach(function (val, k) {
                            const fkat = (val[0].code).toString().substring(0, 1)
                            if (fkat!=kat) {
                                kat=fkat;
                                sumkat = {
                                    iskat: true,
                                    code: fkat,
                                    name: getKatName(fkat),
                                    plan: val[0].plan,
                                    last_fact: val[0].last_fact
                                }
                                arr.push(sumkat)
                            } else {
                                sumkat.plan = sumkat.plan + val[0].plan
                                sumkat.last_fact = sumkat.last_fact + val[0].last_fact
                            }
                            arr.push(
                                val[0]
                            )
                            gr.push({
                                name: val[0].code.toString() + ' ' + val[0].name,
                                label: val[0].name,
                                parent: val[0].code.toString()[0],
                                value: parseFloat((val[0].last_fact / divider).toFixed(2))
                            })
                        })
                        const dm = {
                            table: arr,
                            graph: gr
                        }
                        map.set(key, dm)
                    })
                return regionsMap;
            }

            const res = mergeArray(data);
            // console.log(res)
            return res;
        },
        data3OnlyOrgs () {
            const res = []
            this.data2.data.forEach(function (item) {
                if (item.orgs) {
                    res.push(item)
                }
            })
            console.log(res)
            return res
        }
    },
    mounted () {
        this.loadData();
        this.fillData();
    }

}
;
</script>

<style scoped>
.code_text {
    font-size: 0.8em;
}

.nameOfTheClass {
    max-width: 10px;
}

.my-chart {
    width: 75%;
    margin-left: 10%;
}
.katclas {
    font-weight: bold;
    font-style: italic;
    font-size: 1.2em;
}
</style>