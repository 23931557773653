









































































import { Vue, Component, Prop } from 'vue-property-decorator';
import CTreeTableItem, { ICollapsHide } from '@/modules/budget/components/cTreeTableItem.vue';

export interface ITreeColumns {
    property: string;
    title: string;
    filterable?: boolean;
    collapseIcon?: boolean;
    sortable?: boolean;
}


@Component({
    name: 'c-tree-table',
    components: {
        'c-tree-table-item': CTreeTableItem
    }
})
export default class CTreeTable extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private columns!: ITreeColumns[];
    @Prop({
        required: true,
        default: []
    })
    private rows!: any[];

    private pgRows = 1;
    private curPage = 1;
    private perPage = 10;

    private get getColumns(): ITreeColumns[] {
        return this.columns.slice();
    }
    // ----------------скрытие дочерних элементов
    private collapseHide: ICollapsHide[] = []; // массив настроек для скрытия элементов
    private collapseHideUpdate = true; // перезаписывать ли массив collapseHide в изначальное значение
    // ----------------сортировка
    private sortField: string | null = null; // поле для сортировки
    private sortIncrease = true; // сортировка по возрастанию - true
    // ----------------------
    private filterTxt = '';

    private get getRows(): any[] {
        this.pgRows = this.rows.length;
        const arr = this.cloneArr(this.rows);
        this.collapseHideUpdate = true;
        return this.setTreeIndx(arr, '');
    }

    private get getFilterRows(): any[] {
        this.collapseHideUpdate = true;
        if (this.filterTxt.trim() === '') {
            return this.getRows;
        }

        const filterRows = this.cloneArr(this.getRows.slice());
        const res = this.findTextInObj(filterRows);
        return res;
    }

    private get getSortedRows(): any[] {
        if (this.sortField !== null) {
            return this.sortTreeData();
        }
        return this.getFilterRows.slice();
    }

    // массив элементов с текущей страницы
    private get getRowsToPage(): any[] {
        const rows = this.cloneArr(this.getSortedRows.slice());
        if (rows === undefined) {
            return [];
        }
        let begIndx = (this.curPage - 1) * this.perPage;
        if (begIndx >= rows.length) { begIndx = rows.length - 1; }
        const endIndx = begIndx + this.perPage;
        if (endIndx >= rows.length) {
            rows.slice(begIndx);
        }
        const newArr = this.treeToArr(rows.slice(begIndx, endIndx));

        if (this.collapseHideUpdate) {
            this.collapseHide = [];
            for (const el of newArr) {
                const obj: ICollapsHide = { hidden: false, collapsed: false };
                if (el._isChild) { obj.hidden = true; }
                if (el._hasChild) { obj.collapsed = true; }
                this.collapseHide.push(obj);
            }

            this.collapseHideUpdate = false;
        }
        return newArr;
    }

    private setTreeIndx(arr: any[], indx: string): any[] {
        for (let i = 0; i < arr.length; i++) {
            const el = arr[i];
            let newIndx = String(i);
            if (indx !== '') {
                newIndx = indx + '_' + i;
                el._parIndx = indx;
                el._chldLvl = indx.split('_').length;
                el._isChild = true;
            } else {
                el._firstLvl = true;
            }
            el._indx = newIndx;
            if (el._children !== undefined && el._children !== null && el._children.length) {
                el._hasChild = true;
                el._children = this.setTreeIndx(this.cloneArr(el._children), newIndx);
            }
        }
        return arr;
    }

    private treeToArr(arr: any[]): any[] { // сделать из дерева одномерный массив
        let newArr: any[] = [];
        for (const el of arr) {
            newArr.push(Object.assign({}, el));
            if (el._children !== undefined && el._children !== null && el._children.length) {
                newArr = newArr.concat(this.treeToArr(el._children).slice());
            }
        }
        return newArr;
    }


    // проверка сколапсированы ли дочерние элементы
    private collapseChk(rowIndx: number) {
        const row = this.getRowsToPage[rowIndx];
        const collapse: boolean = this.collapseHide[rowIndx].collapsed;
        if (!(row._isChild || row._hasChild)) { return; }

        for (let i = 0; i < this.getRowsToPage.length; i++) {
            const el = this.getRowsToPage[i];
            if (el._parIndx === row._indx) {
                this.collapseHide[i].hidden = collapse;
                if (el._hasChild) {
                    this.collapseHide[i].collapsed = collapse;
                    this.collapseChk(i);
                }
            }
        }

        this.collapseHide.push({ hidden: false, collapsed: false });
        this.collapseHide.splice(this.collapseHide.length - 1, 1);
    }

    private showChilds(rowIndx: number) { // развернуть дочерний элемент
        this.collapseHide[rowIndx].collapsed = false;
        this.collapseChk(rowIndx);
    }

    private hideChilds(rowIndx: number) { // скрыть дочерние элементы
        this.collapseHide[rowIndx].collapsed = true;
        this.collapseChk(rowIndx);
    }

    // ------------ сортировка --------------------------
    private sortClk(col: ITreeColumns) {
        if (!col.sortable) { return; }
        if (col.property !== this.sortField) {
            this.sortField = col.property;
            this.sortIncrease = true;
        } else {
            this.sortIncrease = !this.sortIncrease;
        }
        this.sortTreeData();
    }

    private sortTreeData(): any[] {
        this.collapseHideUpdate = true;
        const cloneRows = this.cloneArr(this.getFilterRows);
        const sortedArr: any[] = [];
        this.sortTreeDataItems([{ arr: cloneRows }], sortedArr);
        const newArr = this.sortedArrToGetRows(sortedArr);
        return newArr;
    }

    private sortTreeDataItems(arrTree: any[], sortedArr: any[]) {
        const childArr: any[] = [];
        if (arrTree.length < 1) { return; }
        for (const elTr of arrTree) {
            for (const el of elTr.arr) {
                if (el._children !== undefined && el._children !== null && el._children.length > 0) {
                    childArr.push({ _parIndx: el._parIndx, arr: this.cloneArr(el._children) });
                    el._children = null;
                }
            }
            this.sortData(elTr.arr);
            if (elTr.arr.length > 0) {
                sortedArr.push({ _parIndx: elTr.arr[0]._parIndx, arr: elTr.arr });
            }
            if (childArr.length > 0) {
                this.sortTreeDataItems(childArr, sortedArr);
            }
        }
    }

    private sortedArrToGetRows(sortedArr: any[]): any[] {
        let newArr: any[] = [];

        for (let i = 0; i < sortedArr.length; i++) {
            const sEl = sortedArr[i];
            if (sEl._parIndx === undefined) {
                newArr = this.cloneArr(sEl.arr);
                sortedArr.splice(i, 1);
                break;
            }
        }
        this.setChildElement(newArr, sortedArr);
        return newArr;
    }

    private setChildElement(newArr: any[], sortedArr: any[]) {
        if (sortedArr.length < 1) { return; }
        for (const el of newArr) {
            if (sortedArr.length < 1) { return; }
            if (el._hasChild) {
                if (el._children === null || el._children.length < 1) {
                    for (let i = 0; i < sortedArr.length; i++) {
                        const sEl = sortedArr[i];
                        if (sEl._parIndx === el._indx) {
                            el._children = this.cloneArr(sEl.arr);
                            sortedArr.splice(i, 1);
                            this.setChildElement(el._children, sortedArr);
                            break;
                        }
                    }
                } else {
                    this.setChildElement(el._children, sortedArr);
                }
            }
        }
    }

    private sortData(arr: any[]) {
        const sortField = this.sortField;
        if (arr.length < 1 || sortField === null) { return; }

        const type = typeof (arr[0][sortField]);
        if (type === 'number') {
            if (this.sortIncrease) {
                arr.sort(function (d1: any, d2: any) { return (d1[sortField] - d2[sortField]); });
            } else {
                arr.sort(function (d1: any, d2: any) { return (d2[sortField] - d1[sortField]); });
            }
        } else if (type === 'string') {
            if (this.sortIncrease) {
                arr.sort(function (d1: any, d2: any) { return (d1[sortField].toLowerCase() > d2[sortField].toLowerCase()) ? 1 : -1; });
            } else {
                arr.sort(function (d1: any, d2: any) { return (d1[sortField].toLowerCase() < d2[sortField].toLowerCase()) ? 1 : -1; });
            }
        }
    }

    // ------------------- фильтрация --------------------------

    private findTextInObj(filterRows: any[]): any[] {
        const rows = this.cloneArr(filterRows);
        const newArr: any[] = [];
        const filterTxt = this.filterTxt.trim().toLowerCase();

        for (const obj of rows) {
            let newObj = null;
            for (const col of this.columns.slice()) {
                if (col.filterable) {
                    const prop = obj[col.property];
                    if (prop !== null && prop !== undefined) {
                        let str = '';
                        if (typeof (prop) === 'string') {
                            str = prop;
                        } else if (typeof (prop) === 'number') {
                            str = prop.toString();
                        }
                        if (str.toLowerCase().indexOf(filterTxt) >= 0) {
                            newObj = Object.assign({}, obj);
                            newObj._children = null;
                            newObj._hasChild = null;
                        }
                    }
                }
            }
            if (obj._children) {
                const childArr = this.findTextInObj(obj._children);
                if (childArr.length > 0) {
                    if (newObj === null) {
                        newObj = Object.assign({}, obj);
                    }
                    newObj._children = childArr;
                    newObj._hasChild = true;
                }
            }
            if (newObj !== null) {
                newArr.push(newObj);
            }
        }
        return newArr;
    }

    // ---------------------------------------------------------
    private cloneArr(arr: any[]): any[] {
        const newArr: any[] = [];
        for (const el of arr) {
            newArr.push(Object.assign({}, el));
        }

        return newArr;
    }
}
